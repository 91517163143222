import Image from "next/image";
import photo from "../../public/arabic_teachers.svg";
import selectTeacher from "../../public/selectTeacher.webp";
import Book from "../../public/Book.svg";
import classroom from "../../public/classroom.svg";
import teachWithUs from "../../public/teachWithUs.webp";
import verifiedID from "../../public/verifiedID.svg";
import price from "../../public/price.svg";
import schedule from "../../public/schedule.svg";
import startClass from "../../public/startClass.svg";
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useTranslation } from 'next-i18next';
import Head from 'next/head';
import dynamic from 'next/dynamic';
import { NextSeo } from 'next-seo';
import Link from 'next/link';


function Home({ title, description, ogImage }) {

  const { t } = useTranslation('index'); // common as default

  const steps = [
    {
      title: t('findYourIdealTutor'),
      description: t('findYourIdealTutorDescription'),
      image: selectTeacher
    },
    {
      title: t('scheduleLessons'),
      description: t('scheduleLessonsDescription'),
      image: Book
    },
    {
      title: t('enterTheVirtualClassroom'),
      description: t('enterTheVirtualClassroomDescription'),
      image: classroom
    },
  ];



  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={ogImage} />
        <meta property="og:url" content="https://ourguid.com/" />
        <meta property="og:type" content="website" />
      </Head>
      {/* <div className="h-screen bg-[blue]"></div> */}

      <div className="max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
        <div className="rounded-lg overflow-hidden lg:grid lg:grid-cols-2 lg:gap-4">
          <div className="pt-10 pb-12 px-6 sm:pt-16 sm:px-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
            <div className="lg:self-center">
              <h2 className="text-3xl font-extrabold text-blue sm:text-4xl">
              {t('masterArabic')}
              </h2>
              <p className="mt-4 text-lg leading-6 text-blue">
              {t('learnArabicAtYourPace')}
              </p>
              <a
                href="/findTeacher"
                className="mt-8 bg-blue border border-transparent rounded-xl shadow px-4 py-3 inline-flex items-center text-base font-medium text-white"
              >
                {t('tryALesson')}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="mx-2 h-5 w-5"
                  viewBox="2 1 16 16"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 1.414L10.586 9H7a1 1 0 100 2h3.586l-1.293 1.293a1 1 0 101.414 1.414l3-3a1 1 0 000-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </a>
            </div>
          </div>
          <div className="relative -mt-6 aspect-w-5 aspect-h-3 md:aspect-w-2 md:aspect-h-1"> 
      <Image
        className="transform translate-x-6 translate-y-6 rounded-md object-cover object-left-top sm:translate-x-16 lg:translate-y-20"
        src={photo}
        alt="App screenshot"
      />
    </div>
        </div>
      </div>


{/* services */}
{/* services */}
<div className="container mx-auto p-8 py-16 bg-gray-50"> 
  <h2 className="text-4xl font-bold mb-4 text-center text-blue">{t('whyLearnWithOurguid')}</h2>
  <p className="text-lg mb-8 text-center text-blue">{t('introMessage')}</p>

  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
  <div className="p-4 text-center transition-transform hover:-translate-y-1 hover:shadow-lg scale-105 flex flex-col items-center">
      <Image
              className="mx-auto h-24 w-24 object-cover rounded-full border-2 border-blue-500" 
              src={verifiedID}
        alt={t('verifiedID')}
        width={100}
        height={100}
        loading="lazy"
      />
      <h3 className="text- blue text-2xl mb-2">{t('verifiedID')}</h3>
      <p className="text- blue">{t('verifiedIDDescription')}</p>
    </div>

    <div className="p-4 text-center transition-transform hover:-translate-y-1 hover:shadow-lg scale-105 flex flex-col items-center">
      <Image
              className="mx-auto h-24 w-24 object-cover rounded-full border-2 border-blue-500" 
              src={price}
        alt={t('price')}
        width={100}
        height={100}
        loading="lazy"
      />
      <h3 className="text- blue text-2xl mb-2">{t('price')}</h3>
      <p className="text- blue">{t('priceDescription')}</p>
    </div>

    <div className="p-4 text-center transition-transform hover:-translate-y-1 hover:shadow-lg scale-105 flex flex-col items-center">
      <Image
              className="mx-auto h-24 w-24 object-cover rounded-full border-2 border-blue-500" 
              src={schedule}
        alt={t('schedule')}
        width={100}
        height={100}
        loading="lazy"
      />
      <h3 className="text- blue text-2xl mb-2">{t('schedule')}</h3>
      <p className="text- blue">{t('scheduleDescription')}</p>
    </div>

    <div className="p-4 text-center transition-transform hover:-translate-y-1 hover:shadow-lg scale-105 flex flex-col items-center">
      <Image
              className="mx-auto h-24 w-24 object-cover rounded-full border-2 border-blue-500" 
              src={startClass}
        alt={t('startClass')}
        width={100}
        height={100}
        loading="lazy"
      />
      <h3 className="text- blue text-2xl mb-2">{t('startClass')}</h3>
      <p className="text- blue">{t('startClassDescription')}</p>
    </div>
  </div>
</div>



{/* How it works - Enhanced Numbers for Better Visibility */}
<div className="container mx-auto pt-12 px-4 sm:px-6 lg:px-8">
  <div className="text-center">
    <h2 className="text-4xl font-bold mb-4 text-blue">{t('howOurguidWorks')} ?</h2>
    <p className="text-lg mb-8 text-blue">{t('howOurguidWorksDescription')}</p>
  </div>

  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
    {steps.map((step, i) => (
      <div key={i} className="bg-white rounded-lg shadow-lg overflow-hidden transform hover:scale-105 transition-transform duration-300">
        <div className="relative">
        <div className="w-full pb-[56.25%] relative">
            <Image
              className="absolute top-0 left-0 w-full h-full object-cover"
              src={step.image}
              alt={`Step ${i+1}`}
              width={450}  // Keeping these for reference; actual size is controlled by container
              height={300}
              loading="lazy"
            />
          </div>
          {/* Enhanced step number styling */}
          <div className="absolute top-5 right-5 bg-blue text-white font-bold text-2xl rounded-full h-12 w-12 flex items-center justify-center -mt-8 -mr-8 shadow-xl">
            {i+1}
          </div>
        </div>
        <div className="p-6">
          <h3 className="font-semibold text-xl mb-2 text-gray-900">{step.title}</h3>
          <p className="text-gray-600">{step.description}</p>
        </div>
      </div>
    ))}
  </div>


</div>


<div className="text-center mt-6 py-4 bg-green text-white shadow-lg">
    <h2 className="text-2xl font-bold mb-1">{t('satisfactionGuarantee')}</h2>
    <p className="px-4">{t('satisfactionGuaranteeDescription')}</p>
  </div>

{/* Teach with ourguid - Mobile First Design */}
<div className="container mx-auto p-4 md:p-8 bg-white rounded-lg shadow-lg">
  <div className="flex flex-col md:flex-row items-stretch gap-8">
    {/* Image on the left for desktop */}
    <div className="md:flex md:flex-col md:justify-start w-full md:w-1/2">
      <div className="w-full h-auto overflow-hidden rounded-lg shadow-md mb-4 md:mb-0">
        <Image
          className="w-full h-auto object-cover"
          src={teachWithUs}
          alt="Teach with Ourguid"
          layout="responsive"
          width={800} // Assuming an aspect ratio, adjust as necessary
          height={600} // Adjust based on the aspect ratio of your image
          loading="lazy"
        />
      </div>
    </div>
    
    {/* Text content and CTA buttons */}
    <div className="flex flex-col md:flex-grow md:w-1/2">
  <div className="mb-4">
    <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold mb-4 text-blue">{t('teachWithOurguid')}</h2>
    <p className="text-base md:text-lg lg:text-xl mb-8 text-blue">{t('teachWithOurguidDescription')}</p>

    {/* Sub-sections */}
    <div className="space-y-6">
      {/* Find New Students */}
      <div>
        <h3 className="text-xl md:text-2xl font-semibold mb-2">{t('findNewStudents')}</h3>
        <p className="text-base md:text-lg text-gray-700">{t('findNewStudentsDescription')}</p>
      </div>

      {/* Grow Your Business */}
      <div>
        <h3 className="text-xl md:text-2xl font-semibold mb-2">{t('growYourBusiness')}</h3>
        <p className="text-base md:text-lg text-gray-700">{t('growYourBusinessDescription')}</p>
      </div>

      {/* Get Paid Securely */}
      <div>
        <h3 className="text-xl md:text-2xl font-semibold mb-2">{t('getPaidSecurely')}</h3>
        <p className="text-base md:text-lg text-gray-700">{t('getPaidSecurelyDescription')}</p>
      </div>
    </div>
  </div>

  {/* CTA buttons aligned at the bottom */}
    {/* CTA buttons aligned just below the description */}
    <div className="flex flex-col gap-4 mb-8">
  <Link href="/JoinAsTeacher" passHref legacyBehavior>
    <a className="inline-flex items-center justify-center bg-blue text-white rounded-lg w-full px-4 md:px-6 py-3 transform hover:scale-105 transition-transform duration-300 ease-in-out shadow-lg">
      {t('becomeATeacher')}
    </a>
  </Link>
  <Link href="/FAQ" passHref legacyBehavior>
    <a className="inline-flex items-center justify-center bg-green text-white rounded-lg w-full px-4 md:px-6 py-3 transform hover:scale-105 transition-transform duration-300 ease-in-out shadow-lg">
      {t('learnMore')}
    </a>
  </Link>
</div></div>
  </div>
</div>
</>
  );
}




export const getStaticProps = async ({ locale }) => {
  const translations = await serverSideTranslations(locale, ['navbar', 'index', 'footer', 'signup']);
  return {
    props: {
      ...translations,
      title: "Find your Arabic teacher",
      description: "Discover experienced Arabic tutors tailored to meet your learning goals.",
      ogImage: "https://ourguid.com/opengraph-image.png",
    },
  };
};



export default Home;